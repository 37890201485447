<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    
      <b-container fluid class="mt--6">
        <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(userFormSubmit)"
              novalidate
            >
              <h6 class="heading-small text-muted mb-4">User information</h6>

              <div class="">
                <b-row>
                  <b-col lg="6">
                    <base-input :disabled="isUpdate()"
                      type="text"
                      :name="'Username'"
                      label="Username"
                      :required="true"
                      placeholder="Username"
                      v-model="userForm.username"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input
                      type="email"
                      :required="true"
                      :name="'Email'"
                      :rules="email"
                      label="Email address"
                      placeholder="mike@email.com"
                      v-model="userForm.email"
                    >
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :required="true"
                      label="First Name"
                      :name="'First Name'"
                      placeholder="First Name"
                      v-model="userForm.first_name"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Last Name'"
                      :required="true"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="userForm.last_name"
                    >
                    </base-input>
                  </b-col>

                  <b-col lg="6">
                    <base-input
                      type="select"
                      :required="true"
                      label="Role"
                      :name="'Role'"
                      placeholder="Role"
                      v-model="userForm.role"
                      :options="roles"
                      :key-field="'name'" 
                      :value-field="'name'"
                      :text-field="'name'"
                    >
                      
<!--select class="form-control">
                      <option v-for="role in roles" :key="role.name" :value="role.name">{{role.name}}</option>
                    </select>-->
                      
                    
                   
                  
                    </base-input>
                  </b-col>

                  <b-col lg="6" v-if="!isUpdate()">
                    <base-input
                      type="password"
                      :required="true"
                      label="Password"
                      rules="min:8"
                      :name="'Password'"
                      placeholder="Password"
                      v-model="userForm.password"
                    >
                    </base-input>
                  </b-col>

                  <b-col lg="12" v-if="isUpdate()">
                    <b-form-checkbox class="mb-3" v-model="userForm.locked">
                      Disabled
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>

              <base-button
               
                type="primary"
                native-type="submit"
                class="my-4"
              >
                {{ isUpdate() ? "Update Information" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>

          <hr />
          <validation-observer
            v-if="isUpdate()"
            v-slot="{ handleSubmit }"
            ref="passwordFormValidator"
          >
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(passwordFormSubmit)"
              novalidate
            >
              <h6 class="heading-small text-muted mb-4">Reset Password</h6>

              <div class="">
                <b-row>
                  <b-col lg="6">
                    <base-input
                      type="password"
                      :required="true"
                      label="Password"
                      rules="min:8"
                      :name="'Password'"
                      placeholder="Password"
                      v-model="userPasswordForm.password"
                    >
                    </base-input>
                  </b-col>
                </b-row>
              </div>

              <base-button
               
                type="primary"
                native-type="submit"
                class="my-4"
              >
                Save New Password
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
         </b-overlay>
      </b-container>
   
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as userRepo from "@/api-repo/user";
import { fullname } from "@/util/fullname";
import { capitalize } from "@/util/capitalize";
import * as roleRepo from '@/api-repo/roles'


import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
  
  },
  data() {
    return {
      isBusy: false,
      userForm: {
        first_name: null,
        last_name: null,
        _id: null,
        password: null,
        email: null,
        locked: null,
        username: null,
      },
      userPasswordForm: {
        password: null,
      },
    };
  },
  created() {
    this.getInitData();
     if (this.isUpdate()) {
      this.getData();
    }
    
   
  },
  methods: {
    generateBreadCrumb() {
      return [{label: "Users", route: "Users"},{label: this.isUpdate() ? this.userForm.first_name + ' ' +this.userForm.last_name : "Add New User"}];
    },
    fullname,
    capitalize,
    getInitData: async function() {
      let res = await roleRepo.getAll(this.userStore.state.token);
      this.roles = res.data.data;
      console.log(this.roles);
    },
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await userRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        //successMessage('User created successfully');
        //this.$router.replace({ path: "/security/users" });
        this.userForm.first_name = result.data.data.first_name;
        this.userForm.last_name = result.data.data.last_name;
        this.userForm.email = result.data.data.email;
        //this.user.first_name = dresult.data.data.password
        this.userForm.locked = result.data.data.locked;
        this.userForm.username = result.data.data.username;
        this.userForm.role = result.data.data.role;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
    isUpdate: function () {
      return this.$route.params.id != null;
    },
    userFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
             await userRepo.create(
              this.userForm,
              this.userStore.state.token
            );
            successMessage("User created successfully");
            this.$router.replace({ path: "/security/users" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
             await userRepo.update(
              this.$route.params.id,
              this.userForm,
              this.userStore.state.token
            );
            successMessage("User updated successfully");
            
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
    passwordFormSubmit: async function () {
      const isValid = await this.$refs.passwordFormValidator.validate();
      if (isValid) {
        try {
          this.isBusy = true;
           await userRepo.changePassword(
            this.$route.params.id,
            this.userPasswordForm.password,
            this.userStore.state.token
          );
          successMessage("Password updated successfully");
          // this.$router.replace({ path: "/security/users" });
        } catch (e) {
          handleError(e);
        } finally {
          this.isBusy = false;
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">

          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button @click="newUser()" size="sm" type="neutral"><i class="mr-1 fas fa-plus" />Add User</base-button>
        </b-col>
      </b-row>
    </base-header>
  <b-container fluid class="mt--6">
    <b-row>
      <b-col >
        <b-card no-body>
          <b-card-header class="border-0">
            <b-row>
              <b-col >
              </b-col>
              <b-col cols="3">
              <form>
                <b-form-group class="mb-0">
                  <b-input-group >
                    <b-input-group-prepend is-text>

                      <i class="fas fa-search" />
                    </b-input-group-prepend>
                    <b-form-input v-model="search" placeholder="Search"></b-form-input>

                  </b-input-group>
                </b-form-group>

              </form>
          </b-col>
          </b-row>
          </b-card-header>

          <b-table class=" w-100"
            header-row-class-name="thead-light"
            :busy.sync="isBusy"
            :filter="search"
            :per-page="perPage"
            :currentPage="currentPage"
            :items="provider"
            :fields="fields"
            >
            <template #cell(name)="row">
              <span class="font-weight-600 name mb-0 text-sm">{{fullname(row.item.first_name, row.item.last_name)}}</span>
            </template>
            <template #cell(role)="row">
              {{capitalize(row.item.role)}}
            </template>
            <template #cell(actions)="row">
              <router-link :to="'/security/users/' + row.item._id" class="p-2"><i class="fa-lg fas fa-arrow-right"></i></router-link>
            </template>
            <template #cell(locked)="row">
              <span size="" class="badge badge-dot mr-4  ">
                <i v-if="row.item.locked" class="bg-warning"></i>
                <i v-else class="bg-success"></i>
                <span class="status">{{row.item.locked ? 'Locked' : 'Active'}}</span>
              </span>
            </template>
        </b-table>
        <b-card-footer class="py-4 d-flex justify-content-end">
            <base-pagination @change="reloadRows" v-model="currentPage" :per-page="perPage" :total="total"></base-pagination>
        </b-card-footer>
    </b-card>
  </b-col>
</b-row>
</b-container>
</div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import * as userRepo from '@/api-repo/user'
import {fullname} from '@/util/fullname'
import {capitalize} from '@/util/capitalize'


export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
  },
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      total: 1,
      rows: [],
      search: null,
      fields: [
        {key: 'name', label: 'Name'},
        {key: 'email', label: 'Email'},
        {key: 'role', label: 'Role'},
        {key: 'locked', label: 'Locked'},
        {key: 'actions', label: ''},
      ],
    };
  },
  methods: {
    generateBreadCrumb() {
      return [{label: "Users", route: "Users"}];
    },
    fullname,
    capitalize,
    reloadRows: function(e) {
      this.currentPage = e
    },
    newUser: function() {
      this.$router.push({path: '/security/users/new'})
    },
    provider: async function(ctx) {

      this.isBusy = true;
      let result = await userRepo.getAll(ctx.filter || '', ctx.currentPage, ctx.perPage, this.userStore.state.token);
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total = result.data.data[0].total.length && result.data.data[0].total[0].count;
      return result.data.data[0].rows;

    },
  }
};
</script>

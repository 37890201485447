import axios from "axios"



export const create = (item, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `role`, {
    ...item
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  console.log(id)
  return axios.put(process.env.VUE_APP_API_URL + `role/` + id, {
      ...item
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getAll = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `role`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `role/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}